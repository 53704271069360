import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login',  pathMatch: 'full' },

  //home
  { path: 'home',                       loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)                       },

  //login
  { path: 'login',                      loadChildren: () => import('./login/login/login.module').then( m => m.LoginPageModule)              },
  { path: 'login/selectsite',           loadChildren: () => import('./login/selectsite/selectsite.module').then( m => m.LoginPageModule)    },
  { path: 'login/logout',               loadChildren: () => import('./login/logout/logout.module').then( m => m.LogoutPageModule)           },
  { path: 'login/wachtwoord-vergeten',  loadChildren: () => import('./login/wachtwoord-vergeten/wachtwoord-vergeten.module').then( m => m.WachtwoordVergetenPageModule) },
  { path: 'login/wachtwoord-wijzigen',  loadChildren: () => import('./login/wachtwoord-wijzigen/wachtwoord-wijzigen.module').then( m => m.WachtwoordWijzigenPageModule) },

  //aanvraag
  { path: 'stap1',                      loadChildren: () => import('./stap1/stap1.module').then( m => m.Stap1PageModule) },
  { path: 'stap1/:soort',               loadChildren: () => import('./stap1/stap1.module').then( m => m.Stap1PageModule) },
  { path: 'stap2/:soort',               loadChildren: () => import('./stap2/stap2.module').then( m => m.Stap2PageModule) },
  { path: 'stap3',                      loadChildren: () => import('./stap3/stap3.module').then( m => m.Stap3PageModule) },
  { path: 'stap4',                      loadChildren: () => import('./stap4/stap4.module').then( m => m.Stap4PageModule) },
  { path: 'bevestiging',                loadChildren: () => import('./bevestiging/bevestiging.module').then( m => m.BevestigingPageModule) },
  { path: 'bevestiging-klemmen/:id',    loadChildren: () => import('./bevestiging-klemmen/bevestiging-klemmen.module').then( m => m.BevestigingKlemmenPageModule) },

  //wegsleep
  { path: 'wegsleep',                    loadChildren: () => import('./wegsleep/wegsleep.module').then( m => m.WegsleepPageModule)                },
  { path: 'wegsleep2/:id',               loadChildren: () => import('./wegsleep2/wegsleep2.module').then( m => m.Wegsleep2PageModule)             },
  { path: 'wegsleep3/:id',               loadChildren: () => import('./wegsleep3/wegsleep3.module').then( m => m.Wegsleep3PageModule)             },
  { path: 'wegsleep4/:id',               loadChildren: () => import('./wegsleep4/wegsleep4.module').then( m => m.Wegsleep4PageModule)             },

  //aanmelden
  { path: 'aanmelden/stap1',             loadChildren: () => import('./aanmelden/stap1/stap1.module').then( m => m.Stap1PageModule)               },
  { path: 'aanmelden/stap2/:id',         loadChildren: () => import('./aanmelden/stap2/stap2.module').then( m => m.Stap2PageModule)               },
  { path: 'aanmelden/stap3/:id',         loadChildren: () => import('./aanmelden/stap3/stap3.module').then( m => m.Stap3PageModule)               },
  { path: 'aanmelden/bevestiging',       loadChildren: () => import('./aanmelden/bevestiging/bevestiging.module').then( m => m.BevestigingPageModule) },


  //afmelden
  { path: 'afmelden/stap1',               loadChildren: () => import('./afmelden/stap1/stap1.module').then( m => m.Stap1PageModule)               },
  { path: 'afmelden/stap2/:id',           loadChildren: () => import('./afmelden/stap2/stap2.module').then( m => m.Stap2PageModule)               },
  { path: 'afmelden/stap3/:id',           loadChildren: () => import('./afmelden/stap3/stap3.module').then( m => m.Stap3PageModule)               },
  { path: 'afmelden/bevestiging',         loadChildren: () => import('./afmelden/bevestiging/bevestiging.module').then( m => m.BevestigingPageModule) },

  //klemmen
  { path: 'klemmen/stap1',                loadChildren: () => import('./klemmen/stap1/stap1.module').then( m => m.Stap1PageModule)                },
  { path: 'klemmen/stap2/:id',            loadChildren: () => import('./klemmen/stap2/stap2.module').then( m => m.Stap2PageModule)                },
  { path: 'klemmen/stap3/:id',            loadChildren: () => import('./klemmen/stap3/stap3.module').then( m => m.Stap3PageModule)                },
  { path: 'klemmen/bevestigen',           loadChildren: () => import('./klemmen/bevestigen/bevestigen.module').then( m => m.BevestigenPageModule) },

  //verplaatser
  { path: 'verplaatser/stap1',            loadChildren: () => import('./verplaatser/stap1/stap1.module').then( m => m.Stap1PageModule)            },
  { path: 'verplaatser/stap2/:id',        loadChildren: () => import('./verplaatser/stap2/stap2.module').then( m => m.Stap2PageModule)            },
  { path: 'verplaatser/stap3/:id',        loadChildren: () => import('./verplaatser/stap3/stap3.module').then( m => m.Stap3PageModule)            },
  { path: 'verplaatser/stap4/:id',        loadChildren: () => import('./verplaatser/stap4/stap4.module').then( m => m.Stap4PageModule) },
  { path: 'verplaatser/stap4-lozerit/:id',loadChildren: () => import('./verplaatser/stap4-lozerit/stap4-lozerit.module').then( m => m.Stap4LozeritPageModule) },


  //ontklemmen
  { path: 'ontklemmen/stap1',               loadChildren: () => import('./ontklemmen/stap1/stap1.module').then( m => m.Stap1PageModule)           },
  { path: 'ontklemmen/stap2/:id',           loadChildren: () => import('./ontklemmen/stap2/stap2.module').then( m => m.Stap2PageModule)           },
  { path: 'ontklemmen/stap3/:id',           loadChildren: () => import('./ontklemmen/stap3/stap3.module').then( m => m.Stap3PageModule)           },
  { path: 'ontklemmen/stap4/:id',           loadChildren: () => import('./ontklemmen/stap4/stap4.module').then( m => m.Stap4PageModule)           },
  { path: 'ontklemmen/bevestiging-wegsleep',loadChildren: () => import('./ontklemmen/bevestiging-wegsleep/bevestiging-wegsleep.module').then( m => m.BevestigingWegsleepPageModule) },
  { path: 'ontklemmen/bevestiging-afgerond',loadChildren: () => import('./ontklemmen/bevestiging-afgerond/bevestiging-afgerond.module').then( m => m.BevestigingAfgerondPageModule) },
  { path: 'wegsleep4-lozerit',              loadChildren: () => import('./wegsleep4-lozerit/wegsleep4-lozerit.module').then( m => m.Wegsleep4LozeritPageModule) },
  { path: 'fotos/:id',                      loadChildren: () => import('./fotos/fotos.module').then( m => m.FotosPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
