import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { SignaturePadModule } from 'angular2-signaturepad';

import { Camera, CameraOptions } from '@ionic-native/camera/ngx';//import in app.module.ts

import { HttpClientModule} from "@angular/common/http";

import * as $ from 'jquery';


import { MediaCapture } from '@ionic-native/media-capture/ngx';

import {LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {ImagePicker} from '@ionic-native/image-picker/ngx';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        SignaturePadModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Camera,
        MediaCapture,
        LocalNotifications,
        ImagePicker
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
