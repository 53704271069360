import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor() { }

  async get(key){

    const valueGet = await Storage.get({ key: key });
    var value = valueGet.value;

    console.log('value',value);

    return value;
  }
}
