import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { interval, Subscription} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Location } from '@angular/common';
import { App as CapacitorApp } from '@capacitor/app';

//import { Plugins, ActionSheetOptionStyle } from '@capacitor/core';
import { Dialog } from '@capacitor/dialog';
import { Storage } from '@capacitor/storage';
//const { Modals } = Plugins;

import axios from 'axios';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { DatabaseService } from './database.service';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'List',
      url: '/list',
      icon: 'list'
    }
  ];

    apiAliveTimer: Subscription;
    films: Observable<any>;

  constructor(
    private platform: Platform,
    private router: Router,
    private httpClient : HttpClient,
    private localNotifications: LocalNotifications,
    public database: DatabaseService,
    private location: Location,
  ) {
    this.initializeApp();

    CapacitorApp.addListener('backButton', ({canGoBack}) => {
      if(!canGoBack){
      } else {
        window.history.back();
      }
    });

    this.platform.backButton.subscribe(() => {
      window.history.back();
    });

  }



  initializeApp() {
    this.platform.ready().then(() => {
      document.body.setAttribute('data-theme', 'light');
      document.body.classList.toggle('dark', false);
    });
  }


  async confirmExitApp() {
    // const alert = await this.alertCtrl.create({
    //   header: 'Confirmation Exit',
    //   message: 'Are you sure you want to exit ?',
    //   backdropDismiss: false,
    //   cssClass: 'confirm-exit-alert',
    //   buttons: [{
    //     text: 'Stay',
    //     role: 'cancel',
    //     handler: () => {
    //       console.log('Application exit  handler');
    //     }
    //   }, {
    //     text: 'Exit apps',
    //     handler: () => {
    //       navigator['app'].exitApp();
    //     }
    //   }]
    // });

    alert('afsluiten?');

    // await alert.present();
  }


async ngOnInit() {
    console.log('onInit');

    await console.log(Storage);

      this.apiAliveCheck();
      this.pushNotification();

      this.apiAliveTimer= interval(60000).subscribe((x =>{
          this.apiAliveCheck();
      }));
  }

  async apiAliveCheck() {
    var parts = window.location.href.split('/');
    var lastSegment = parts.pop() || parts.pop();  // handle potential trailing slash

    if(lastSegment!=='login' && lastSegment!=='logout' && lastSegment!=='/' && lastSegment!=='wachtwoord-vergeten' && lastSegment!=='wegsleep.app' && lastSegment!=='192.168.10.27:8100' && lastSegment!=='localhost' && lastSegment!=='localhost:8100'){
      var tokenValid        = false;
      const tokenValueGet   = await Storage.get({ key: 'login' });
      const tokenValue      = tokenValueGet.value;

      const baseUrlGet   = await Storage.get({ key: 'baseUrl' });
      const baseUrl      = baseUrlGet.value;

      if(tokenValue){
        var tokenObject = JSON.parse(tokenValue);
        var token       = tokenObject.token;

        this.films = this.httpClient.get(baseUrl+"/api/token.json?token="+token);
        this.films
        .subscribe(response => {
          if(response.succes==true){
            tokenValid = true;
          }
          else{
            tokenValid = false;

            this.runTokenNotValid();
          }
        })
      }
      else{
        this.runTokenNotValid();
      }
    }
  }

  async runTokenNotValid(){
    console.log(this.router.url,'invalid token');

    await Dialog.alert({
      title: 'Stop',
      message: 'Je sessie is verlopen. Je moet opnieuw inloggen',
    });

    // let alertRet = await Modals.alert({
    //   title: 'Stop',
    //   message: 'Je sessie is verlopen. Je moet opnieuw inloggen'
    // });

    location.href = '/login/logout'
  }

  async pushNotification(){
    console.log('function pushNotification');


    const notificationTokenGet = await Storage.get({ key: 'notificationToken' });
    var notificationToken = notificationTokenGet.value;

    const baseUrlGet = await Storage.get({ key: 'baseUrl' });
    var baseUrl = baseUrlGet.value;

    var tokenGet = await Storage.get({ key: 'token' });
    var token = tokenGet.value;
    var siteToken = token;

    const siteIdGet = await Storage.get({ key: 'siteId' });
    var siteId = siteIdGet.value;


    if(!notificationToken && siteId){
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      PushNotifications.addListener(
        'registration',
        (token: Token) => {
          Storage.set({key:'notificationToken',value:token.value});


          var formData = new FormData();
          formData.append("notificationToken", token.value);

          var url = baseUrl+"/api/login/notificationToken.json?token="+siteToken+'&siteId='+siteId;
          axios({
            method: 'POST',
            url: url,
            data: formData,
          })
          .then(function (response) {
            console.log(response);
          })
        },
      );

      PushNotifications.addListener('registrationError', (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      });
    }

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {

        console.log(notification);

        var id = new Date().getTime()+Math.floor(Math.random() * 100000000);
        // Schedule a single notification
        this.localNotifications.schedule({
          id: id,
          title: notification.title,
          text: notification.body,
          sound: this.platform.is('android')? 'file://sound.mp3': 'file://beep.caf'
        });
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
      },
    );
  }

  goBack(){
    alert('go back');
    window.history.back();
  }

}
